html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-primary {
  font-size: 24px;
  line-height: 30px;

  text-align: center;
}

.text-secondary {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.7;

  text-align: center;
}

.swiper {
  /*width: 100%;*/
  /*height: 100%;*/
  overflow: visible !important;
}

.swiper-slide {
  background: black;
}

.swiper-slide {
  transform: scale(0.85);
  transform-origin: center;
  opacity: .2;
  transition: .3s all;
}

.swiper-slide-prev {
  transform: scale(0.925);
  transform-origin: center bottom;
  opacity: .4;
}

.swiper-slide-active {
  transform: scale(1);
  transform-origin: center;
  opacity: 1;
}

.btn-primary {
  display: inline-block;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  line-height: 18px;

  padding: 8px 24px;
  cursor: pointer;

  transition: .3s all;
}

.btn-primary:hover {
  color: black;
  background: white;
}


.mt-4 {
  margin-top: 20px;
}


/* This fires as soon as the element enters the DOM */
.connect-wallet {
  animation: connect-wallet 2s alternate forwards;
}

@keyframes connect-wallet {
  from {
    opacity: 0;
    transform: translate(0, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 20px);
  }
}

.connect-wallet-transition-enter {
  opacity: 0;
  transition: 3s all;
}

/* This is where we can add the transition*/
.connect-wallet-transition-enter-active{
  opacity: 1;
}
/* This fires as soon as the this.state.showList is false */
.connect-wallet-transition-exit{
  opacity: 1;
}
/* fires as element leaves the DOM*/
.connect-wallet-transition-exit-active{
  opacity: 1;
}

a[href] {
  color:white;
  text-decoration: underline;
}

.swiper-container {
  overflow: visible !important;
}