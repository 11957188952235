body {
  margin: 0;
  background: #000;
  font-family: Abel;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;

  text-align: center;
  color: #FFFFFF;
}

.slider-item {
  height: 100%;
  background: red;
}

